import React, { useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios';
import sha256 from 'crypto-js/sha256';
import { BASE_URL } from '../../../config';
import LoginSection from '../login/loginSection';
import RegistrationSection from '../registration/registrationSection';
import { formatCurrency } from '../../utils';
import { setClearCartAction, setRemoveProductAction, setRemoveProductsAction } from '../../redux/cart/cartSlice';
import { navigate } from 'gatsby';
import axiosWithAuth from '../../utils/axios';
import { useTranslation } from 'react-i18next';


const CheckoutSection = () => {
    
    const { t } = useTranslation();

    const {isAuth,userData} = useSelector(state => state.auth)
    const cart = useSelector(state => state.cart);
    const dispatchAction = useDispatch();
    const [formType, setFormType] = useState("Login");
    const [step, setStep] = useState(1);
    const [studentData, setStudentData] = useState(null);
    const [creatingOrder, setCreatingOrder] = useState(false)
    

    React.useEffect(()=>{
        getUserData()
    },[isAuth,userData])

    const getUserData = async () => {
        try {
            const result = await axiosWithAuth().get(BASE_URL+"/students/me")
            setStudentData(result.data);
            emptyCart(result.data.enrolledCourses)
        } catch (error) {
            
        }
    }
      

      const handleSubmitCreateOrder = async ()=>{
        try {
            setCreatingOrder(true)
            //crear la orden

            if (typeof window !== 'undefined') {
        
                //Send event to GTM
                window.dataLayer.push({
                  event: "begin_checkout",
                  currency: "USD",
                  value: parseFloat(cart.total),
                  items: cart.products.map((data)=>{
                    return {
                        item_id: data.id,
                        item_name: data.name,
                        price: parseFloat(data.price),
                        quantity: 1
                    }
                })
                });
                
              }
              
            const payload = {
                studentId : userData.id,
                orderItems: cart.products.map((data)=>{
                    return {
                        courseId: data.id,
                        couponUsed : data.coupon
                    }
                })
            }
            const result = await axiosWithAuth().post(BASE_URL+"/orders", payload)
            if(result.data == "Ok"){
                return navigate("/student-profile")
            }
            window.location.href = result.data
        } catch (error) {
            setCreatingOrder(false)
        }
      }

      const emptyCart = (enrolledCourses) => {
        const userCourses = enrolledCourses.map(data => data.course.id); // Obtén la lista de cursos comprados del usuario
        const cartCourses = cart.products; // Obtén los cursos en el carrito
        if(userCourses.length > 0){
            const coursesToRemove = cartCourses.filter(course => userCourses.includes(course.id));
            if(coursesToRemove.length > 0){
                dispatchAction(setRemoveProductAction(coursesToRemove.map(data => data.id)));
            }
        }
        
      };

    return (
        <>
        {cart &&
        <Formik
    >
        {({ values, handleSubmit }) => (
            <>
                <section className="checkout-area pb-70">
                    <div className="container">
                        
                        {!isAuth && formType == 'Login' &&
                        <LoginSection source="checkout" setFormType={setFormType} />
                        }
                        {!isAuth && formType == 'Signup' &&
                        <RegistrationSection source="checkout" setFormType={setFormType} />
                        }
                        {isAuth &&
                            <div className="row mt-4">
                            <div className="col-lg-6">
                                <div className='your-order'> 
                                <h3>{t('UserData')}</h3>
                                <p>
                                    <b>{t('FirstName')}</b>: {studentData && studentData.firstName}<br/>
                                    <b>{t('LastName')}</b>: {studentData && studentData.lastName}<br/>
                                    <b>{t('Email')}</b>: {studentData && studentData.email}<br/>
                                </p>
                                
                                </div>

                            </div>
                            <div className="col-lg-6">
                                <div className="your-order mb-30 ">
                                    <h3>{t('Summary')}</h3>
                                    <div className="your-order-table table-responsive">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className="product-name">{t('Course')}</th>
                                                    <th className="product-total">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cart &&
                                                studentData &&
                                                cart.products
                                                .filter(data => !studentData.enrolledCourses.map(data => data.id).includes(data.id)) 
                                                .map((data, index) => (
                                                    <tr key={index} className="cart_item">
                                                        <td className="product-name">
                                                            {data.name}
                                                        </td>
                                                        <td className="product-total">
                                                            <span className="amount">${formatCurrency(data.price)}</span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            <tfoot>
                                                <tr className="cart-subtotal">
                                                    <th>Subtotal</th>
                                                    <td><span className="amount">USD ${formatCurrency(cart.subtotal)}</span></td>
                                                </tr>
                                                <tr className="order-total">
                                                    <th>{t('Discount')}</th>
                                                    <td><span className="amount">USD ${formatCurrency(cart.discount)}</span></td>
                                                </tr>
                                                <tr className="order-total">
                                                    <th>Total</th>
                                                    <td><strong><span className="amount">USD ${formatCurrency(cart.total)}</span></strong>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>

                                    <div className="payment-method">
                                        {/* <div className="accordion" id="checkoutAccordion">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="checkoutOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#bankOne" aria-expanded="true"
                                            aria-controls="bankOne">
                                            Direct Bank Transfer
                                        </button>
                                    </h2>
                                    <div id="bankOne" className="accordion-collapse collapse show"
                                        aria-labelledby="checkoutOne" data-bs-parent="#checkoutAccordion">
                                        <div className="accordion-body">
                                            Make your payment directly into our bank account. Please use your
                                            Order ID
                                            as the payment reference. Your order won’t be shipped until the
                                            funds have
                                            cleared in our account.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="paymentTwo">
                                        <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#payment"
                                            aria-expanded="false" aria-controls="payment">
                                            Cheque Payment
                                        </button>
                                    </h2>
                                    <div id="payment" className="accordion-collapse collapse"
                                        aria-labelledby="paymentTwo" data-bs-parent="#checkoutAccordion">
                                        <div className="accordion-body">
                                            Please send your cheque to Store Name, Store Street, Store Town,
                                            Store
                                            State / County, Store
                                            Postcode.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="paypalThree">
                                        <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#paypal"
                                            aria-expanded="false" aria-controls="paypal">
                                            PayPal
                                        </button>
                                    </h2>
                                    <div id="paypal" className="accordion-collapse collapse"
                                        aria-labelledby="paypalThree" data-bs-parent="#checkoutAccordion">
                                        <div className="accordion-body">
                                            Pay via PayPal; you can pay with your credit card if you do not have
                                            a
                                            PayPal account.
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {cart.products.length > 0 &&
                            <>
                            {cart.total > 0 && 
                            <div className='alert alert-warning'>
                                {t('dLocalGoText')}
                            </div>
                            }
                            {cart.total == 0 && <button className="edu-btn" onClick={()=>handleSubmitCreateOrder()} disabled={creatingOrder} type="button">{creatingOrder ? t('Loading') : t('Finish')}</button>}
                            {cart.total > 0 && <button className="edu-btn" onClick={()=>handleSubmitCreateOrder()} disabled={creatingOrder} type="button">{creatingOrder ? t('Loading') : t('PayNow')}</button>}
                            </>
                            }
                            
                                        
                                    </div>
                                </div>
                            </div>
                            </div>
                        }
                            
                    </div>
                </section>
            </>
        )}
    </Formik>
        }
            
        </>
    );
};

export default CheckoutSection;